import {css} from "@emotion/react";
import {Property} from "csstype";

export const underline = css`
    text-decoration: underline;
`;

export const textAlign = (align: Property.TextAlign) => css`
    text-align: ${align};
`;
